.grants{
    margin-left: 70px;
		padding:36px;
		width:100%;
}
@media only screen and (max-width: 768px) {
	.grants {
    margin-left: 0!important;
    padding: 0!important;
  
  }
}