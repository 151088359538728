.projectDetails {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
  height: 40rem;

  .desc {
    flex: 0 0 auto;
    width: 50%;
  }

  img {
    flex: 0 0 auto;
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .projectDetails {
    display: block;
    height: 100vh;
    margin: 0 !important;

    .desc {
      width: 100%;
    }

    img {
      margin: 0 !important;
      padding-bottom: 1rem;
      height: auto;
    }
  }

  .projectD {
    display: none !important;
  }
}