body {
  background: #242424 !important;
  color: #eeeeee !important;
  font-family: "Jost Regular", sans-serif !important;
  font-weight: normal;
  font-size: 16px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  overflow-x: hidden !important;
  overflow-y: auto !important; /* Ensure vertical scrolling */
  overscroll-behavior: none;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Make scrollbar invisible */
}

.display-block {
  display: block !important;
}

a {
  color: #0000ff;
}
a:hover {
  color: grey;
}

.bold-text {
  font-weight: bold;
}

img {
  cursor: pointer;
}

.apps {
  margin-left: 70px;
  padding: 36px;
  width: 100%;
  a {
    text-decoration: none;
    color: #777777;
    &:hover {
      color: white;
    }
  }
}

@media only screen and (max-width: 768px) {
  .apps {
    overflow-y: auto;
    margin-left: 0 !important;
    padding: 0 !important;
    .next {
      display: block;
      height: 100vh;
      margin-bottom: 10rem;
    }
  }
}
