.wrap {
    max-width: 1410px;
    width: 90%;
    .head {
        width: 100%;
        padding: 80px 0 0;
       margin: 0 60px;
        .routes{
            width: 85%;
        }
    }
}

@media only screen and (max-width: 768px) {
.wrap{
   overflow-x: hidden !important;
   width:100%;
   max-width: 100%;
   .head{
    padding:1rem;
   margin:0;
    .routes{
       width:100%;
    }
   }
  }
}