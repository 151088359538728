.aboutme {
  margin-left: 70px;
  padding: 40px;
  width: 100%;
  .first {
    .avatar {
      width: 40%;
      text-align: center;
      margin-right:2rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .next{
      width:60%;
  }
  }
  .grants {
    margin-top: 10rem;
    span {
      padding-bottom: 50px;
      a {
		margin-left: 5px;
        color: #aaaaaa;
        font-style: normal;
        font-weight: normal;
        text-decoration: underline;
      }
    }
  }
  .next {
    a {
      margin-left: 5px;
      color: #aaaaaa;
      font-style: normal;
      font-weight: normal;
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 768px) {
	.aboutme{
    margin-left: 0!important;
    padding: 0!important;
    .first{
      flex-direction: column;
      .avatar{
       display:flex;
       justify-content: center;
        img{
     
        border-radius: 50%;
        }
      }
      .next{
        width:100%;
      }
    }
  
  }
 
}