//Jost

$font-black: "Jost Black";
$font-blackitalic: "Jost BlackItalic";
$font-bold: "Jost Bold";
$font-bolditalic: "Jost BoldItalic";
$font-extrabold: "Jost ExtraBold";
$font-extrabolditalic: "Jost ExtraBoldItalic";
$font-extralight: "Jost ExtraLight";
$font-extralightitalic: "Jost ExtraLightItalic";
$font-italic: "Jost ExtraItalic";
$font-light: "Jost Light";
$font-lightitalic: "Jost LightItalic";
$font-medium: "Jost Medium";
$font-mediumitalic: "Jost MediumItalic";
$font-regular: "Jost Regular";
$font-semibold: "Jost SemiBold";
$font-semibolditalic: "Jost SemiBoldItalic";
$font-thin: "Jost Thin";
$font-thinitalic: "Jost ThinItalic";


